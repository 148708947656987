// base
.floorplans{
	.floorplan{
		padding: 20px 0;
	}
	
	aside{
		padding-bottom: 20px;
	}

	h3{
		font-size: 30px;
		font-weight: bold;
	}

	ul{
		padding: 20px 0;
	}
	
	li{
		list-style: inside disc;
	}

	a{
		.button;
		.outline;
		text-align: center;
		width: auto;
		max-width: 90%;
		display: inline-block;
		padding: 0 25px;
		margin: auto auto 10px;
		height: 60px;
		line-height: 60px;
		font-size: 1.5rem;
	}

	article{
		p{
			padding-bottom: 1.5em;
		}
	}

	.tablet({
		.floorplan{
			display: flex;
		}

		aside{
			width: 45%;
			order: 2;
		}

		article{
			order:1;
			width: 50%;
		}
	})
};

