// base
.gallery{
	padding: 40px 0;

	a{
		position: relative;
		display: inline-block;
	}

	img{
		display: block;
		max-width: 100%;
	}

	.tablet({
		.gallery-images{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		a{
			width: 32%;
			margin-bottom: 15px;
		}
	})
};

