// base
.feature{
	background: url(/assets/img/fade.png) top left no-repeat;
	background-size: cover;
	padding: 15px;
	text-align: center;

	article{
		font-size: 2rem;
		padding-bottom: 15px;
		color: @blue;
	}

	aside{
		a{
			.button;
			.outline;
		}
	}
	
	.tablet({
		.inner{
			display: flex;
			justify-content: space-between;
		}

		article{
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		aside{
		}
	});


	.desktop({
		padding: 30px 0 30px 30px;
		article{
			text-align: left;
			font-size: 2.8rem;
		}

		aside{
			padding-top: 10px;
			display: flex;
			justify-content: flex-end;

			a{
				margin: 0 10px;
				width: 200px;
				font-size: 1.7rem;
			}
		}
	});
};

