// base
.reviews{
	position: relative;
	padding: 40px 0;
	line-height: 2;

	article{
		position: relative;
	}

	ul{
		position: relative;
		text-align: center;
		list-style: none;
		padding: 50px 0;
		max-width: 65%;
		margin: auto;
	}

	li{
		display: none;
		opacity: 0;

		&.active{
			display: block;
			opacity: 1;
		}
	}
	
	p + p{
		line-height: 1.5;
		color: #f28f00;
		font-weight: bold;
	}

	.left{
		position: absolute;
		top: 120px;
		left: 20px;
		width: 21px;
		height: 36px;
		background: url(/assets/img/left.png) center left no-repeat;
	}

	.right{
		position: absolute;
		top: 120px;
		right: 20px;
		width: 21px;
		height: 36px;
		background: url(/assets/img/right.png) center left no-repeat;
	}
.tablet({
	//tablet specific
	h2{
		text-align: right;
		margin-bottom: 25px;
		.grad;
	}
	
	&.alt{
		h2{
			text-align: left;
			.gradalt;
		}
	}
});


.desktop({
	// desktop specific
});
};

