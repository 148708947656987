// base
.location-nav{
	position: relative;
	text-align: center;

	ul{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	li{
		list-style: none;
		padding: 10px;
	}

	a{
		color: @black;
		font-weight: bold;

		&:hover{
			color: @blue;
		}
	}
};