// base
.two-by{
	position: relative;
	padding: 20px 0;

	.location{
		position: relative;
	}

	.location + .location{
		margin-top: 20px;
	}

	a{
		position: relative;
		display: block;
		position: absolute;
		top:0;
		left:0;
		bottom:0;
		right:0;
		background: rgba(0,0,0,.5);
		font-size: 2.5rem;
		text-align: center;

		*{
			color: #fff;
		}
	}

	h2{
		padding-bottom: 0;
	}

	header{
		position: absolute;
		top:0;
		left:0;
		right:0;
		padding: 35px 15px 0;
	}

	footer{
		position: absolute;
		bottom:0;
		left:0;
		right:0;
		padding: 0 15px 35px;
	}

	button{
		.button;
		.outline;
		border: 2px solid #fff;
		color: #fff;
		margin: 0 auto;
		line-height: 66px;
	}
	
	
	.tablet({
		.inner{
			display: flex;
			justify-content: space-between;
		}

		.location{
			width: 49%;
			margin: 0 !important;
		}
	});


	.desktop({
		.location{
			width: 48%;

			img{
				width: 100%;
			}
		}
	});
};

