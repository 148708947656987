@font-face {
	font-family: 'fa-subset';
	src:
		url('assets/fa-subset.ttf?kg94j6') format('truetype'),
		url('assets/fa-subset.woff?kg94j6') format('woff'),
		url('assets/fa-subset.svg?kg94j6#fa-subset') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="fa-"], [class*=" fa-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'fa-subset' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

//social icons, some have multiple, pick the one you want for the theme
.fa-twitter:before {
	content: "\f099"; //just bird
	//content: "\f081"; //square
}
.fa-facebook:before {
	//content: "\f082"; //square
	//content: "\f230"; //official
	content: "\f09a"; //just f
}
.fa-linkedin:before {
	content: "\f0e1"; //just letters
	//content: "\f08c"; //square
}
.fa-pinterest:before {
	content: "\f231"; // just p
	//content: "\f0d2"; //circle
	//content: "\f0d3"; //square
}
.fa-youtube:before {
	content: "\f16a"; //play btn
}
.fa-instagram:before {
	content: "\f16d";
}
.fa-vimeo:before {
	//content: "\f194"; //squared version
	content: "\f27d";  //just v
}

//the rest
.fa-plus:before {
	content: "\f067";
}
.fa-minus:before {
	content: "\f068";
}
.fa-search:before {
	content: "\f002";
}
.fa-check:before {
	content: "\f00c";
}
.fa-close, .fa-remove, .fa-times {
	&:before {
		content: "\f00d";
	}
}
.fa-arrow-circle-o-down:before {
	content: "\f01a";
}
.fa-arrow-circle-o-up:before {
	content: "\f01b";
}
.fa-play-circle-o:before {
	content: "\f01d";
}
.fa-play:before {
	content: "\f04b";
}
.fa-chevron-left:before {
	content: "\f053";
}
.fa-chevron-right:before {
	content: "\f054";
}
.fa-plus-circle:before {
	content: "\f055";
}
.fa-minus-circle:before {
	content: "\f056";
}
.fa-times-circle:before {
	content: "\f057";
}
.fa-check-circle:before {
	content: "\f058";
}
.fa-question-circle:before {
	content: "\f059";
}
.fa-info-circle:before {
	content: "\f05a";
}
.fa-times-circle-o:before {
	content: "\f05c";
}
.fa-check-circle-o:before {
	content: "\f05d";
}
.fa-arrow-left:before {
	content: "\f060";
}
.fa-arrow-right:before {
	content: "\f061";
}
.fa-arrow-up:before {
	content: "\f062";
}
.fa-arrow-down:before {
	content: "\f063";
}
.fa-share:before {
	content: "\f064";
}
.fa-exclamation-circle:before {
	content: "\f06a";
}
.fa-chevron-up:before {
	content: "\f077";
}
.fa-chevron-down:before {
	content: "\f078";
}
.fa-arrow-circle-left:before {
	content: "\f0a8";
}
.fa-arrow-circle-right:before {
	content: "\f0a9";
}
.fa-arrow-circle-up:before {
	content: "\f0aa";
}
.fa-arrow-circle-down:before {
	content: "\f0ab";
}
.fa-link:before {
	content: "\f0c1";
}
.fa-reply:before {
	content: "\f112";
}
.fa-chevron-circle-left:before {
	content: "\f137";
}
.fa-chevron-circle-right:before {
	content: "\f138";
}
.fa-chevron-circle-up:before {
	content: "\f139";
}
.fa-chevron-circle-down:before {
	content: "\f13a";
}
.fa-play-circle:before {
	content: "\f144";
}
.fa-arrow-circle-o-right:before {
	content: "\f18e";
}
.fa-arrow-circle-o-left:before {
	content: "\f190";
}

