// base
.locations{
	position: relative;
	padding: 45px 15px;
	background-color: rgba(183, 183, 183, 0.22);

	.location{
		position: relative;
		padding: 40px 0 0;
		text-align: center;
	}

	.actions{
		padding: 40px 0;
		text-align: center;
		a{
			.button;
			.outline;
			text-align: center;
			margin: 20px auto 0;
		}
	}

	p:last-of-type{
		padding-top: 10px;
	}

	p a{
		color: @blue;
		text-decoration: underline;

		&:hover{
			text-decoration: none;
		}
	}

	.tablet({
		//tablet specific
		article{
			display: flex;
		}

		.map{
			width: 30%;
		}

		.info{
			width: 68%;
		}
	});


	.ultra({
		padding: 60px;
		header{
			width: 100%;
			padding-bottom: 40px;
		}

		article{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		.map{
			width: 330px;
			// background: #333;

			img{
				display: block;
				width: 100%;
			}
		}

		.info{
			width: ~"calc(100% - 330px)";
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			box-sizing: border-box;
			padding-left: 30px;
		}

		.location{
			width: 48%;
			text-align: left;
			padding-top: 0;
		}

		h3{
			padding-bottom: 10px;
		}

		.actions{
			width: 100%;
			display: block;

			a{
				max-width: 48%;
				float: left;
				font-size: 15px;
			}

			a+a{
				float: right;
			}
		}
	});
};

