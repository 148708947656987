// standard colors
@text:			#7a7a7a;
@primary:		#2379BB;
@gray-lt:		#9ca8a9;
@gray-md:		#7a7a7a;
@gray-dk:		#393939;
@black:			#303030;
@white:			#FFFFFF;
@orange:		#F57B00;
@red:			#FF0000;
@green: 		#21cfcb;
@blue: 			#4786C6;

// custom colors

// breakpoints
@phablet: 		480px;
@tablet: 		768px;
@desktop: 		980px;
@ultra: 		1300px;
@maxInner: 		1400px;

// font settings
@main: 			'Poppins', 'Inter', Helvetica, Arial, sans-serif;
@heading: 		'Poppins', 'Inter', Helvetica, Arial, sans-serif;