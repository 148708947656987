@import '../base/_utilities';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;900&display=swap');

// universal
* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&:focus {
		outline: @primary auto 3px;
	}
}

img{
	max-width: 100%;
}


// links
a, a:visited {
	text-decoration: none;
	transition: ease all .3s;
	outline: none;

	&:hover, &:focus {
		text-decoration: none;
	}

	&:active {
		text-decoration: none;
	}

	/* mobile tap color */
	&:link {
		-webkit-tap-highlight-color : rgba( 0, 0, 0, 0.3 );
	}
}


// headings
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
	text-rendering: optimizelegibility;
	margin: 0;
	font-weight:bold;
	line-height: 1.3;
	color: @blue;
}

h1, .h1 {
	.fontSize(3.9,3.9,3.9);
}

h2, .h2 {
	.fontSize(3.9,3.9,3.9);
	padding-bottom: 15px;
}

.btn {
	display: inline-block;
	appearance: none;
	background: transparent;
	border-radius: 0;
	text-align: center;
	cursor: pointer;
	font-size: 1rem;
	line-height: normal;
	
	&:hover, &:focus, &:active {
		
	}
}

.button{
	position: relative;
	display: block;
	line-height: 74px;
	height: 74px;
	border: 1px solid rgba(34, 34, 34, 0);
	background: @blue;
	color: #fff;
	font-size: 2rem;
	font-weight: bold;
	width: 100%;
	max-width: 300px;
	border-radius: 3px;
	transition: ease all .3s;
	margin-bottom: 10px;

	&:hover{
		background: @black;
		color: #fff;
	}
}

.grad{
	padding: 10px;
	background: url(/assets/img/rightfade.png) top left repeat-x;
	background-size: cover;
}

.gradalt{
	background: url(/assets/img/leftfade.png) top left repeat-x;
}

.outline{
	background: none;
	border: 2px solid @blue;
	color: #000;
}

p {
	margin: 0;
}

ul, ol {
	margin: 0;
	padding:0;
}

p, ul, ol, dl {
	// + p, + ul, + ol, + dl {
	// 	margin-top: 1.5em;
	// }
}

nav, .nav, nav ul, .nav ul {
	list-style-type: none;
	padding-left: 0;
}

hr {
	border:none;
	background:#eee;
	height:2px;
	width:60px;
	margin:35px auto 0;
}

.alignleft, .alignright {
	margin-bottom: 1em;
}

address {
	font-style: normal;
}

.center {
	text-align: center;
}

.upper {
	text-transform: uppercase;
}

.screen-reader-text {
	position: absolute !important;
	left: -999em;
}

.html-times {
	font-size: 1.5em;
	vertical-align: sub;
}

// phablet
.phablet({
});