// base
.generic-content{
	position: relative;
	padding: 25px 0;

	p, ul, ol, dl{
		position: relative;
		padding-bottom: 1.5em;
	}

	a{
		color: @blue;
		text-decoration: underline;

		&:hover{
			text-decoration: none;
		}
	}
	
	ul{
		margin-left: 22px;
	}

	.tablet({
		//tablet specific
		&.alt{
			h2{
				text-align: left;
				.gradalt;
			}
		}

		h2{
			text-align: right;
			margin-bottom: 25px;
			.grad;
		}
	});


	.desktop({
		padding-top: 60px;
		
	});
};

