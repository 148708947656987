html { 
	font-size: 62.5%; 
} 

body{
	font-family: @main;
	font-size: 1.6rem;
	line-height: 1.5;
	color: @black;
}

#site{
	position: relative;
	padding: 0 15px;
	overflow: hidden;
}

img{
	display: block;
}

.inner{
	position: relative;
	margin: auto;
	max-width: 1400px;
	width: 100%;
}


.tablet({
});


.desktop({
	#site{
		display: flex;
		max-width: 1430px;
		margin: auto;
		overflow: hidden;

		main{
			width: ~"calc(100% - 420px)"; // full widht less sidebar width plus 40 in spacing, accomodates image overflow hiding
		}
		
	}
});