// base
.location-intro{
	position: relative;
	text-align: center;

	.inner{
		width: 100%;	
		overflow: hidden;
	}

	article{
		padding: 20px;
	}
	
	p{
		font-size: 36px;
		line-height: 1;
		padding: 20px 0;
	}

	a{
		.button;
		.outline;
		text-align: center;
		margin: auto;
	}

	.desktop({
		img{
			position: relative;
			display: block;
			min-height: 600px;
			max-height: 700px;
			width: auto;
			max-width: none;
		}
		.intro{
			position: absolute;
			top: 10%;
			right: 10%;
			width: 40%;
			background: rgba(255,255,255,.7);
			padding: 45px;
			h1{
				text-align: left;
			}
			p{
				color: @blue;
				text-align: left;
				padding: 30px 0;
				line-height: 1.3;
			}
		}
		
	});
};