.block.form{
	padding:0;
	h2{
		em{
			font-style: normal;
			font-weight: normal;
		}
	}

	a{
		background: none;
		display: inline;
		font-weight: normal;
		padding:0;
		width: auto;
		max-width: 100%;
		color: #4786C6;
		font-size: 1em !important;
	}

	h3 + div{
		padding: 20px 0;
	}

	aside{
		p{
			padding-bottom: 0;
		}
	}

	// a.btn{
	// 	.button;
	// 	.outline;
	// 	text-align: center;
	// 	width: auto;
	// 	max-width: 90%;
	// 	display: inline-block;
	// 	padding: 0 25px;
	// 	margin: auto auto 10px;
	// 	height: 60px;
	// 	line-height: 60px;
	// 	font-size: 1.5rem;
	// 	text-decoration: none;
	// }

	button{
		.button;
		text-align: center;
		width: auto;
		max-width: 90%;
		display: inline-block;
		padding: 0 25px;
		margin: auto auto 10px;
		height: 60px;
		line-height: 60px;
		font-size: 1.5rem;
	}

	label + label{
		padding-left: 20px;
	}

	label span{
		padding-left: 5px;
	}

};

