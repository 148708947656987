// base
.amenities{
	position: relative;
	padding: 15px;
	background: rgba(117, 184, 204, 0.42);

	img{
		display: block;
		width: 100%;
		height: auto;
	}

	aside{
		display: none;
	}

	article{
		position: relative;

		ul, ol, dl, p{
			list-style: inside disc;
			padding-bottom: 1em;
		}

		h3{
			padding-top: 1em;
		}

		a{
			.button;
			.outline;
			text-align: center;
		}
	}
	
	.tablet({
		.inner{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		header{
			width: 100%;
			text-align: center;
			padding: 50px 0 30px;
		}

		aside{
			width: 48%;
			display: block;

			li{
				padding-bottom: 25px;
			}
		}

		article{
			width: 48%;
		}

		ul{
			list-style: none;
		}
	});


	.desktop({
		margin-top: 60px;
		padding: 40px;

		h3{
			font-size: 2.8rem;
		}

		ul{
			padding: 20px 0 30px;
		}

		li{
			padding: 3px 0;
		}
	});
};