// base
.image-cta{
	position: relative;
	padding: 25px 0;

	p{
		position: relative;
		padding-bottom: 1.5em;
	}

	p a{
		.button;
		text-align: center;
	}

	ul.logo-list{
		position: relative;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		list-style: none;

		li{
			width: 32%;
			padding: 5px 0;
		}
	}


	.tablet({
		//tablet specific
		&.alt{
			h2{
				text-align: left;
				.gradalt;
			}
		}

		h2{
			text-align: right;
			margin-bottom: 25px;
			.grad;
		}
	});


	.desktop({
		padding-top: 60px;
		.inner{
			display: flex;
			flex-wrap: wrap;
		}

		&.full{
			article{
				width: 100%;
			}
	
			aside{
				width: 100%;
				padding: 20px 0;
			}
		}

		header{
			width: 100%;
			margin-bottom: 20px;
		}

		article{
			width: 50%;
		}

		aside{
			width: 35%;
			padding: 0 7.5%;
		}

		&.alt{
			article{
				order: 2;
			}

			aside{
				order: 1;
			}
		}
	});
};

