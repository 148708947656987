// base
#header{
	position: relative;
	background: #fff;
	padding: 0 0 30px;

	.inner{
		max-width: 303px;
		margin: auto;
	}

	#logo{
		display: block;
		width: 303px;
		margin-bottom: 30px;
	}

	#nav{
		font-weight: bold;
		padding: 40px 0 0;
		text-align: center;
		display: none;

		li{
			padding: 2px 0;
		}

		a{
			color: @black;
			text-transform: uppercase;
			font-size: 1.8rem;

			&:hover{
				color: @blue;
			}
		}
	}

	#grip{
		position: relative;
		display: block;
		margin: auto;
		width: 40px;
		height: 40px;
		border: 0;
		border-radius: 3px;
		// .indent;
		font-size: 32px;

		span{
			display: none;
		}
	}

	#call{
		text-align: center;
		font-weight: bold;
		padding-top: 40px;
		font-size: 2rem;

		a{
			color: @black;
			text-decoration: none;

			&:hover{
				color: @blue;
			}
		}
	}

	#quick-action{
		padding-top: 40px;
		text-align: center;

		a{
			.button;
		}
	}

	.phone({
		

	});
		
	.tablet({
		
	});


	.desktop({
		width: 380px;
		min-width: 380px;
		max-width: 380px;

		#grip{
			display: none;
		}

		#nav{
			display: block;
		}
	});
}