// base
#footer{
	position: relative;
	padding: 25px;
	background: #5a99d8;
	color: #fff;

	.inner{
		overflow: hidden;
	}

	h3{
		color: #fff;
		font-size: 16px;
	}

	a{
		color: #000;

		&:hover{
			text-decoration: underline;
		}
	}

	nav{
		float: left;
		width: 45%;
	}

	.location{
		float: right;
		width: 50%;
		padding-bottom: 25px;

		ul{
			display: flex;
			padding-top: 5px;
			justify-content: start;
		}

		li{
			width: 32px;
			height: 32px;
			list-style: none;
			padding: 0 10px 0 0;
		}

		a{
			display: block;
			width: 32px;
			height: 32px;
			font-size: 20px;

			&:hover{
				opacity: .5;
			}
		}

		svg{
			height: 24px;
			width: 24px;
		}
	}


	.tablet({
		
	});


	.desktop({
		.inner{
			display: flex;
			flex-wrap: wrap;
		}
		nav{
			position: relative;
			float: none;
			width: 100%;
			padding: 0 50px;
			box-sizing: border-box;

			ul{
				display: flex;
				justify-content: space-between;
			}

			li{
				padding-right: 10px;
			}

			a{
				font-size: 1.7rem;
				text-transform: uppercase;
				line-height: 1.35;

				&:hover{
					color: #000;
				}
			}
		}

		.location{
			width: 50%;
			text-align: center;
			padding: 60px 0 30px;

			h3{
				font-size: 1.9rem;
			}

			ul{
				justify-content: center;
			}
		}
	});
}


#company-meta{
	padding: 10px 0 25px;
	#logo{
		display: block;
		position: relative;
		max-width: 303px;
		margin: 0 auto;

		img{
			display: block;
			width: 100%;
			margin: auto;
		}
	}

	p{
		padding-top: 45px;
		text-align: center;

		a{
			color: #333;
			text-decoration: underline;

			&:hover{
				text-decoration: none;
			}
		}
	}

	.desktop({
		#logo{
			float: left;
			width: 200px;
		}

		p{
			float: right;
		}
	});
}